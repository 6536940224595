import * as React from "react"
import { Link } from "gatsby"
import ReactTooltip from 'react-tooltip'
import "./style.scss"

import homeIcon from "../../images/house-menu_icon.svg"

const Nav = ({ data, homeLink }) => {
    React.useEffect(() => {
        const tooltip = document.querySelector('.__react_component_tooltip');
        tooltip.innerHTML = data.onlineTraining.tooltip;
        tooltip.style.opacity = 0.9;
        tooltip.style.top = "-25px";
    });

    return (
        <ul className="navbar-nav ml-auto main-nav" role="navigation" aria-label="Main">
            <li className="nav-item">
                <Link className="nav-link --home" to={homeLink} title={data.home.title}>
                    <img src={homeIcon} alt={data.home.title} width="19" height="18" />
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="#services" title={data.services.title}>{data.services.label}</Link>
            </li>
            <li className="nav-item">
                <Link id="available-soon" className="nav-link disabled" to="#formation-on-line" data-tip="" data-place="top" data-effect="solid" title={data.onlineTraining.title}>{data.onlineTraining.label}</Link>
                <ReactTooltip />
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="#approche" title={data.approach.title}>{data.approach.label}</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="#a-propos" title={data.about.title}>{data.about.label}</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="#contact" title={data.contact.title}>{data.contact.label}</Link>
            </li>
        </ul>
    )
}

export default Nav;