
import planificationIcon from "./images/planification_icon.svg"
import consultingIcon from "./images/consulting_strategique_icon.svg"
import dashboardIcon from "./images/dashboard_icon.svg"
import apiIcon from "./images/api_icon.svg"
import assistanceIcon from "./images/assistance_icon.svg"
import formationsIcon from "./images/formations_icon.svg"
import appIcon from "./images/app_icon.png"
import briefcaseIcon from "./images/briefcase_icon.png"
import paintIcon from "./images/paint_icon.png"
import trophyIcon from "./images/trophy_icon.png"
import hearthicon from "./images/hearth_icon.png"
import cloudIcon from "./images/cloud_icon.png"
import coffeeIcon from "./images/coffee_icon.png"

const data = {
    navigation: {
        fr: {
            home: {
                title: "avoc - planification horaire avec Hyperplanning",
            },
            services: {
                title: "Planification horaire avec Hyperplanning, Gestion des étudiants, Gestion des enseignants, Gestion des stages, Publication web, Placement de l’horaire automatique, Placement de l’horaire manuel, Séances de cours, formations Hyperplanning",
                label: "Services"
            },
            onlineTraining: {
                title: "Apprendre à utiliser Hyperplanning, formations Hyperplanning vidéo, Capsules vidéode formation Hyperplanning",
                label: "formations on-line",
                tooltip: "disponible bientôt"
            },
            approach: {
                title: "Approche",
                label: "Approche"
            },
            about: {
                title: "L'équipe de avoc est exclusivement composée de professionnels ayant une solide expérience dans le domaine de la planification à forte valeur ajoutée. Ceci afin de répondre au mieux à vos attentes, quelles qu'elles soient!",
                label: "A propos"
            },
            contact: {
                title: "Contact",
                label: "Contact"
            }
        },
        it: {
            home: {
                title: "avoc - pianificazione del tempo con Hyperplanning",
            },
            services: {
                title: "Pianificazione dell'orario con Hyperplanning, Gestione degli studenti, Gestione degli insegnanti, Gestione degli stage, Pubblicazione web, Inserimento automatico dell'orario, Inserimento manuale dell'orario, Sessioni di corso, Hyperplanning corsi",
                label: "Servizi"
            },
            onlineTraining: {
                title: "Imparare a usare Hyperplanning, video di formazione Hyperplanning, video clip di formazione Hyperplanning",
                label: "Formazioni on-line",
                tooltip: "presto disponibile"
            },
            approach: {
                title: "Approccio",
                label: "Approccio"
            },
            about: {
                title: "Il team di Avoc è composto esclusivamente da professionisti con una solida esperienza nel campo della pianificazione ad alto valore aggiunto. Questo è per soddisfare le vostre aspettative, qualunque esse siano!",
                label: "Chi siamo"
            },
            contact: {
                title: "contatto",
                label: "contatto"
            }
        },
        en: {
            home: {
                title: "avoc - time planning with Hyperplanning",
            },
            services: {
                title: "Scheduling with Hyperplanning, Student management, Teacher management, Internship management, Web publishing, Automatic schedule placement, Manual schedule placement, Course sessions, Hyperplanning training",
                label: "Services"
            },
            onlineTraining: {
                title: "Learn to use Hyperplanning, Hyperplanning training videos, Hyperplanning training videos",
                label: "On-line training",
                tooltip: "Available soon"
            },
            approach: {
                title: "Approach",
                label: "Approach"
            },
            about: {
                title: "The avoc team is exclusively composed of professionals with a solid experience in the field of high value-added planning. This is to meet your expectations, whatever they may be!",
                label: "About"
            },
            contact: {
                title: "Contact",
                label: "Contact"
            }
        }
    },
    hero: {
        fr: {
            title: "Planifier vos horaires avec <strong>Hyperplanning</strong> vous donne du fil à retordre?",
            subtitle: "Tout est dans<br />les choses essentielles!",
            mobile: {
                title: "Planifier vos<br />horaires<br />avec<br /><strong>Hyperplanning</strong><br />vous<br />donne du<br />fil à<br />retordre?",
                subtitle: "Tout est<br />dans les choses<br />essentielles!"
            },
            copyright: "Copyright © 2021. Tous les droits réservés.",
            cta: "Découvrez l'offre d'avoc"
        },
        it: {
            title: "Pianificare gli orari con Hyperplanning ti da del filo da torcere?",
            subtitle: "Tutto è<br />nelle cose essenziali!",
            mobile: {
                title: "Pianificare<br />gli orari<br />con<br /><strong>Hyperplanning</strong><br />ti da<br />del<br />filo da<br />torcere?",
                subtitle: "Tutto è<br />nelle cose<br />essenziali!"
            },
            copyright: "Copyright © 2021 avoc. Tutti i diritti riservati.",
            cta: "Scopri l'offerta di Avoc"
        },
        en: {
            title: "Is planning your schedules with Hyperplanning giving you a hard time?",
            subtitle: "It's all<br />in the essentials!",
            mobile: {
                title: "Is planning<br />your schedules<br />with<br /><strong>Hyperplanning</strong><br />giving you<br />a<br />hard time?",
                subtitle: "It's all<br />in the<br />essentials"
            },
            copyright: "Copyright © 2021 avoc. All rights reserved.",
            cta: "Discover Avoc's offer"
        }
    },
    about: {
        "fr": {
            "title": "Une équipe d’experts<br /> à votre service.",
            "description": "L'équipe de <strong>avoc</strong> est exclusivement composée de professionnels ayant une solide expérience dans le domaine de la planification à forte valeur ajoutée. Ceci afin de répondre au mieux à vos attentes, quelles qu'elles soient!",
            "image": {
                "placeholder": "avoc... partout et toujours!",
                "alt": "Hyperplanning, Enseignement supérieur, Enseignement primaire, Enseignement post-obligatoire, Planification horaire avec Hyperplanning, Gestion des étudiants, Gestion des enseignants, Gestion des stages, Publication web, Placement de l’horaire automatique, Placement de l’horaire manuel"
            }
        },
        "en": {
            "title": "A team of experts<br /> at your service.",
            "description": "The <strong>avoc</strong> team is exclusively composed of professionals with a solid experience in the field of high value-added planning. This is to meet your expectations, whatever they may be!",
            "image": {
                "placeholder": "avoc... partout et toujours!",
                "alt": "Hyperplanning, Higher education, Primary education, Post-compulsory education, Timetable planning with Hyperplanning, Student management, Teacher management, Internship management, Web publishing, Automatic timetable placement, Manual timetable placement"
            }
        },
        "it": {
            "title": "Un team di esperti<br /> al vostro servizio.",
            "description": "Il team di <strong>Avoc</strong> è composto esclusivamente da professionisti con una solida esperienza nel campo della pianificazione ad alto valore aggiunto. Proprio per soddisfare le vostre aspettative, qualunque esse siano!",
            "image": {
                "placeholder": "avoc... partout et toujours!",
                "alt": "Hyperplanning, Istruzione superiore, Istruzione primaria, Istruzione post-obbligatoria, Pianificazione dell'orario con Hyperplanning, Gestione degli studenti, Gestione degli insegnanti, Gestione degli stage, Pubblicazione web, Inserimento automatico dell'orario, Inserimento manuale dell'orario"
            }
        }
    },
    footer: {
        "fr": {
            "logo": {
                "alt": "Hyperplanning, Enseignement supérieur, Enseignement primaire, Enseignement post-obligatoire, Planification horaire avec Hyperplanning, Gestion des étudiants, Gestion des enseignants, Gestion des stages, Publication web, Placement de l’horaire automatique, Placement de l’horaire manuel"
            },
            "emailAddress": "Adresse e-mail",
            "copyright": "Copyright © 2021 avoc. Tous les droits réservés."
        },
        "en": {
            "logo": {
                "alt": "Hyperplanning, Higher education, Primary education, Post-compulsory education, Timetable planning with Hyperplanning, Student management, Teacher management, Internship management, Web publishing, Automatic timetable placement, Manual timetable placement"
            },
            "emailAddress": "Email address",
            "copyright": "Copyright © 2021 avoc. All rights reserved."
        },
        "it": {
            "logo": {
                "alt": "Hyperplanning, Istruzione superiore, Istruzione primaria, Istruzione post-obbligatoria, Pianificazione dell'orario con Hyperplanning, Gestione degli studenti, Gestione degli insegnanti, Gestione degli stage, Pubblicazione web, Inserimento automatico dell'orario, Inserimento manuale dell'orario"
            },
            "emailAddress": "Indirizzo e-mail",
            "copyright": "Copyright © 2021 avoc. Tutti i diritti riservati."
        }
    },
    "fr": {
        page: {
            title: "avoc - planification avec hyperplanning",
            description: "Société de conseil spécialisée dans la planification et la gestion des horaires, la gestion des étudiants et des enseignants, le tout avec le logiciel Hyperplanning.",
            kexwords: "Hyperplanning, Enseignement supérieur, Enseignement primaire, Enseignement post-obligatoire, Planification horaire, Gestion des étudiants, Gestion des enseignants, Gestion des stages"
        },
        assistiveText: "Hyperplanning, Enseignement supérieur, Enseignement primaire, Enseignement post-obligatoire, Planification horaire avec Hyperplanning, Gestion des étudiants, Gestion des enseignants, Gestion des stages, Publication web, Placement de l’horaire automatique, Placement de l’horaire manuel, Séances de cours, formations Hyperplanning, planification horaire, horaire écoles, horaire des écoles, confection de l’horaire des écoles, apprendre à utiliser Hyperplanning, formations Hyperplanning vidéo, Capsules vidéode formation Hyperplanning, gestion de la vie des étudiants, gestion des notations, bulletins scolaires, gestion des bulletins scolaires, gestion des absences, gestion des réservations des salles de classe, interactivité, API, Gestion des ressources, Gestion de l’administration,  Gestion des salaires des enseignants, Gestion des écarts,  Gestion de la communication, Alertes SMS, Alertes e-mail, Application iPhone, Application Android, Travail collaboratif à distance",
        servicesTop: {
            title: "Votre investissement, notre priorité!",
            subTitle: "Ce que l’on peut faire pour vous"
        },
        services: [
            {
                id: 'service-1',
                title: 'Planification et gestion des horaires',
                iconImage: planificationIcon,
                iconCaption: 'Un horaire efficace au service de tous',
                description: 'Les spécialistes de Avoc se mettent à votre disposition pour confectionner vos horaires avec <a href="https://www.index-education.com/fr/presentation-hyperplanning.php" target="_blank" title="Hyperplanning"><strong>Hyperplanning</strong></a>, à distance ou directement chez vous dans votre établissement scolaire.'
            },
            {
                id: 'service-2',
                title: 'Consulting strategique, decisionnel et technique',
                iconImage: consultingIcon,
                iconCaption: 'Aide à la décision et études de faisabilité',
                description: 'Avoc réalise et pilote pour vous des missions de conseil allant de la phase d’analyse jusqu’à la mise en oeuvre d’outils en passant par le choix technologique.'
            },
            {
                id: 'service-3',
                title: 'Tableaux de bord et business intelligence',
                iconImage: dashboardIcon,
                iconCaption: 'Exploitation des données pour mieux piloter',
                description: 'Avoc vous fournit des tableaux de bords et des rapports dynamiques, esthétiques, conviviaux pour que vous puissiez les utiliser en réseau avec d’autres collaborateurs.'
            },
            {
                id: 'service-4',
                title: 'Création de portails ad hoc par assemblage de données',
                iconImage: apiIcon,
                iconCaption: 'Interfaçage et évolutivité par les API',
                description: 'Avoc vous aide à assembler toutes vos données Hyperplanning sur des portails web ad hoc (intranet ou extranet) afin de faciliter l’interaction de tous les acteurs de votre établissement scolaire.'
            },
            {
                id: 'service-5',
                title: 'Assistance sur site et a distance',
                iconImage: assistanceIcon,
                iconCaption: 'Externalisation et évolutivité',
                description: 'Pour une réponse encore plus rapide à vos urgences, Avoc met en place pour ses clients une solution d\'assistance à distance simple, fiable et conviviale pendant toute la semaine ouvrée.'
            },
            {
                id: 'service-6',
                title: 'Formations generiques et personnalisees',
                iconImage: formationsIcon,
                iconCaption: 'Notre savoir-faire avec vous et pour vous',
                description: 'Afin d’améliorer la performance de votre équipe, Avoc se met à disposition pour des formations génériques ou spécialisées, directement dans votre établissement scolaire ou à distance.'
            }
        ],
        approachTop: {
            title: "Une question d’organisation",
            subTitle: "Une approche bien rodée",
            description: "L'organisation, c'est d'abord dans la tête. Il n'y a pas de bonne organisation qui ne soit au préalable élaborée en pensée.<br>Bien s'organiser, cela consiste tout d’abord à bien clarifier le but qu'on veut atteindre, à recueillir toute l'information pertinente,<br>à chercher les meilleures alternatives pour l’atteindre, puis à concrétiser la solution choisie dans un plan et une planification appropriés."
        },
        approaches: [
            { id: 'chart-1', percentage: 30, label: "Penser la stratégie", icon: appIcon },
            { id: 'chart-2', percentage: 20, label: "Paramétrer la stratégie", icon: briefcaseIcon },
            { id: 'chart-3', percentage: 50, label: "Réaliser la stratégie", icon: paintIcon },
        ],
        cardsAbout: [
            { title: '100000+', description: 'Ressources planifiées', image: trophyIcon, id: "about1" },
            { title: '30000+', description: 'Heures d’expertise', image: hearthicon, id: "about2" },
            { title: '50', description: 'Installations techniques', image: cloudIcon, id: "about3" },
            { title: '1250', description: 'Tasses de café', image: coffeeIcon, id: "about4" },
        ]
    },
    "en": {
        page: {
            title: "avoc - planning with Hyperplanning",
            description: "Consulting company specialized in the planning and management of schedules, student and teacher management, all with Hyperplanning software.",
            kexwords: "Hyperplanning, Higher education, Primary education, Post-compulsory education, Scheduling, Student management, Teacher management, Internship management"
        },
        assistiveText: "Hyperplanning, Higher education, Primary education, Post-compulsory education, Timetable planning with Hyperplanning, Student management, Teacher management, Internship management, Web publishing, Automatic timetable placement, Manual timetable placement, Classroom sessions, Hyperplanning trainings, school timetable, school timetable, learn to use Hyperplanning, Hyperplanning video trainings Hyperplanning training video clips, student life management, grading management, report cards, report card management, absence management, classroom reservation management, interactivity, API, resource management, administration management, teacher salary management, gap management, communication management, SMS alerts, email alerts, iPhone application, Android application, remote collaborative work",
        servicesTop: {
            title: "Your investment, our priority!",
            subTitle: "What we can do for you"
        },
        services: [
            {
                id: 'service-1',
                title: 'Timetable planning and management',
                iconImage: planificationIcon,
                iconCaption: 'An effective timetable for everyone',
                description: 'Avoc\'s specialists are at your disposal to create your timetable with <a href="https://www.index-education.com/fr/presentation-hyperplanning.php" target="_blank" title="Hyperplanning"><strong>Hyperplanning</strong></a>, remotely or directly at your school.'
            },
            {
                id: 'service-2',
                title: 'Strategic, decision-making and technical consultancy',
                iconImage: consultingIcon,
                iconCaption: 'Decision support and feasibility studies',
                description: 'Avoc carries out and manages consulting assignments for you, ranging from the analysis phase to the implementation of tools, including the technological choice.'
            },
            {
                id: 'service-3',
                title: 'Dashboards and business intelligence',
                iconImage: dashboardIcon,
                iconCaption: 'Using data for better management',
                description: 'Avoc provides you with dynamic, aesthetically pleasing, user-friendly dashboards and reports for you to use in a network with other collaborators.'
            },
            {
                id: 'service-4',
                title: 'Creation of ad hoc portals by assembling data',
                iconImage: apiIcon,
                iconCaption: 'Interfacing and scalability through APIs',
                description: 'Avoc helps you to assemble all your Hyperplanning data on ad hoc web portals (intranet or extranet) in order to facilitate the interaction of all actors of your school.'
            },
            {
                id: 'service-5',
                title: 'On-site and remote assistance',
                iconImage: assistanceIcon,
                iconCaption: 'Outsourcing and scalability',
                description: 'For an even faster response to your emergencies, Avoc sets up for its clients a simple, reliable and user-friendly remote assistance solution during the whole working week.'
            },
            {
                id: 'service-6',
                title: 'General and customized training',
                iconImage: formationsIcon,
                iconCaption: 'Our expertise with and for you',
                description: 'In order to improve the performance of your team, Avec is available for generic or specialized training, directly in your school or remotely.'
            }
        ],
        approachTop: {
            title: "A matter of organization",
            subTitle: "A well-tested approach",
            description: "The organization is first of all in the head. There is no good organization which is not first worked out in thought. Getting organized means first of all clearly clarifying the goal you want to achieve, collecting all the relevant information, looking for the best alternatives to achieve it, then concretizing the chosen solution in a plan and proper planning."
        },
        approaches: [
            { id: 'chart-1', percentage: 30, label: "Thinking the strategy", icon: appIcon },
            { id: 'chart-2', percentage: 20, label: "Setting up the strategy", icon: briefcaseIcon },
            { id: 'chart-3', percentage: 50, label: "Implementing the strategy", icon: paintIcon },
        ],
        cardsAbout: [
            { title: '100000+', description: 'Planned resources', image: trophyIcon, id: "about1" },
            { title: '30000+', description: 'Hours of expertise', image: hearthicon, id: "about2" },
            { title: '50', description: 'Technical installations', image: cloudIcon, id: "about3" },
            { title: '1250', description: 'Coffee cups', image: coffeeIcon, id: "about4" },
        ]
    },
    "it": {
        page: {
            title: "avoc - pianificazione con Hyperplanning",
            description: "Società di consulenza specializzata nella pianificazione e gestione degli orari, gestione degli studenti e degli insegnanti, il tutto con il software Hyperplanning.",
            kexwords: "Iperpianificazione, Educazione superiore, Educazione primaria, Educazione post-obbligatoria, Pianificazione degli orari, Gestione degli studenti, Gestione degli insegnanti, Gestione dei posti"
        },
        assistiveText: "Hyperplanning, Istruzione superiore, Istruzione primaria, Istruzione post-obbligatoria, Pianificazione dell'orario con Hyperplanning, Gestione degli studenti, Gestione degli insegnanti, Gestione degli stage, Pubblicazione web, Inserimento automatico dell'orario, Inserimento manuale dell'orario, Sessioni in classe, Corsi di formazione Hyperplanning, pianificazione dell'orario delle scuole, orario scolastico, imparare a usare Hyperplanning, corsi di formazione video Hyperplanning Hyperplanning training video clips, gestione della vita degli studenti, gestione dei voti, pagelle, gestione delle pagelle, gestione delle assenze, gestione delle prenotazioni in classe, interattività, API, gestione delle risorse, gestione dell'amministrazione, gestione degli stipendi degli insegnanti, gestione delle lacune, gestione della comunicazione, avvisi SMS, avvisi e-mail, applicazione iPhone, applicazione Android, lavoro collaborativo a distanza",
        servicesTop: {
            title: "Il tuo investimento, la nostra priorità!",
            subTitle: "Cosa possiamo fare per te"
        },
        services: [
            {
                id: 'service-1',
                title: 'Pianificazione e gestione degli orari',
                iconImage: planificationIcon,
                iconCaption: 'Un orario efficace al servizio di tutti',
                description: 'Gli specialisti di Avoc sono a tua disposizione per creare i tuoi orari con <a href="https://www.index-education.com/fr/presentation-hyperplanning.php" target="_blank" title="Hyperplanning"><strong>Hyperplanning</strong></a>, sia a distanza che direttamente nella tua scuola.'
            },
            {
                id: 'service-2',
                title: 'Consulenza strategica, decisionale e tecnica',
                iconImage: consultingIcon,
                iconCaption: 'Supporto decisionale e studi di fattibilità',
                description: 'Avoc realizza e pilota missioni di consulenza per voi, dalla fase di analisi all\'implementazione degli strumenti, compresa la scelta della tecnologia.'
            },
            {
                id: 'service-3',
                title: 'Dashboards e business intelligence',
                iconImage: dashboardIcon,
                iconCaption: 'Usare i dati per una migliore gestione',
                description: 'Avoc fornisce dashboard e rapporti dinamici, esteticamente piacevoli e facili da usare per l\'utilizzo in rete con altri collaboratori.'
            },
            {
                id: 'service-4',
                title: 'Creazione di portali ad hoc per assemblamento dati',
                iconImage: apiIcon,
                iconCaption: 'Interfacciamento e scalabilità tramite API',
                description: 'Avoc vi aiuta a riunire tutti i dati di Hyperplanning su portali web ad hoc (intranet o extranet) per facilitare l\'interazione tra tutti gli attori della vostra scuola.'
            },
            {
                id: 'service-5',
                title: 'Assistenza in house e a distanza',
                iconImage: assistanceIcon,
                iconCaption: 'Outsourcing e scalabilità',
                description: 'Per una risposta ancora più rapida alle vostre emergenze, Avoc mette a disposizione dei suoi clienti una soluzione di assistenza remota semplice, affidabile e facile da usare durante tutta la settimana lavorativa.'
            },
            {
                id: 'service-6',
                title: 'Formazioni generiche e su misura',
                iconImage: formationsIcon,
                iconCaption: 'Il nostro know-how con voi e per voi',
                description: 'Per migliorare le prestazioni della vostra squadra, Avoc è disponibile per formazioni generiche o su misura, direttamente nella vostra scuola o a distanza.'
            }
        ],
        approachTop: {
            title: "Una questione di organizzazione",
            subTitle: "Un approccio ben collaudato",
            description: "L'organizzazione è prima di tutto nella mente. Non esiste una buona organizzazione se non è prima elaborata nella vostra mente. Organizzarsi bene significa prima di tutto chiarire l'obiettivo che si vuole raggiungere, raccogliendo tutte le informazioni rilevanti, cercando le migliori alternative per raggiungerlo, e poi concretizzando la soluzione scelta in un piano e un programma adeguati."
        },
        approaches: [
            { id: 'chart-1', percentage: 30, label: "Pensare la strategia", icon: appIcon },
            { id: 'chart-2', percentage: 20, label: "Impostare la strategia", icon: briefcaseIcon },
            { id: 'chart-3', percentage: 50, label: "Attuare la strategia", icon: paintIcon },
        ],
        cardsAbout: [
            { title: '100000+', description: 'Risorse pianificate', image: trophyIcon, id: "about1" },
            { title: '30000+', description: 'Ore di perizia', image: hearthicon, id: "about2" },
            { title: '50', description: 'Installazioni tecniche', image: cloudIcon, id: "about3" },
            { title: '1250', description: 'Tazzine di caffè', image: coffeeIcon, id: "about4" },
        ]
    }
}

export default data;