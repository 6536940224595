import React, { useState } from "react";
import AnimatedNumber from "animated-number-react";
import VisibilitySensor from 'react-visibility-sensor';
import { Fade } from "react-awesome-reveal";
import "./style.scss";


const CardFigure = ({ image, title, description }) => {

    const [visibility, setVisibility] = useState(false);

    const formatValue = (value) => `${value.toFixed(0)}+`.replace(/\B(?=(\d{3})+(?!\d))/g, "’");

    const renderNumbers = () => {
        if (visibility) {
            return (
                <AnimatedNumber
                    value={title}
                    formatValue={formatValue}
                />
            )
        }

        return <p>0+</p>;
    }

    return (
        <Fade>
            <figure className="card-figure">
                <img className="card-figure-image" src={image} alt={description} height="60" width="60" />
                <figcaption className="card-figure-caption">
                    <div className="card-figure-title">
                        <VisibilitySensor
                            onChange={(isVisible) => {
                                setVisibility(isVisible)
                            }}
                        >
                            {renderNumbers}
                        </VisibilitySensor>
                    </div>
                    <p className="card-figure-description">{description}</p>
                </figcaption>
            </figure>
        </Fade>
    );
}

export default CardFigure;