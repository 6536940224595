import { Link } from "gatsby";
import * as React from "react";
import heroBackground from "../../images/hero-bg.jpg";
import Brand from "../brand/brand";
import "./style.scss";

const Hero = ({ data }) => {
    return (
        <div className="hero">
            <div id="hero-carousel" className="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#hero-carousel" data-slide-to="0" className="active"></li>
                    <li data-target="#hero-carousel" data-slide-to="1"></li>
                    <li data-target="#hero-carousel" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="container">
                            <div className="hidden-sm-down">
                                <h1 className="hero-title" dangerouslySetInnerHTML={{ __html: data.title }}></h1>
                                <p className="hero-subtitle" dangerouslySetInnerHTML={{ __html: data.subtitle }}></p>
                            </div>
                            <div className="hidden-sm-up">
                                <h2 className="hero-title" dangerouslySetInnerHTML={{ __html: data.mobile.title }}></h2>
                                <p className="hero-subtitle" dangerouslySetInnerHTML={{ __html: data.mobile.subtitle }}></p>
                                <Link to="#services" className="btn btn-avoc">{data.cta}</Link>
                                <div className="hero-footer">
                                    <Brand slogan={data.copyright} />
                                </div>
                            </div>
                        </div>
                        <img className="carousel-item-image" src={heroBackground} alt="Hyperplanning" />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Hero;