import * as React from "react"
import logo from "../../images/logo.svg"
import "./style.scss"

const Logo = () => {
    return (
        <figure className="logo">
            <img className="logo-image" src={logo} alt="avoc - planification horaire avec hyperplaning" />
        </figure>

    )
}

export default Logo;