import * as React from "react";
import "./style.scss";

const Section = ({ children, id, title, subTitle, className, description, noHeader }) => {

    const sectionClass = (className) ? `section ${className}` : 'section';

    const sectionTitle = (title) ? <h2 className="section-title">{title}</h2> : '';

    const sectionSubtitle = (subTitle) ? <p className="section-subtitle">{subTitle}</p> : '';

    const sectionDescription = (description) ? <p className="section-description" dangerouslySetInnerHTML={{ __html: description }} /> : '';

    const sectionHeader = function () {
        if (!noHeader) {
            return (
                <div className="section-header">
                    {sectionSubtitle}
                    {sectionTitle}
                    {sectionDescription}
                </div>
            )
        }
    }();

    return (
        <section id={id} className={sectionClass}>
            <div className="container">
                {sectionHeader}
                <div className="section-body">
                    {children}
                </div>
            </div>
        </section>
    );
}

export default Section;