import { Link } from "gatsby";
import * as React from "react"
import Logo from "../logo/logo"
import "./style.scss"

const Brand = ({ slogan, homeLink }) => {
    return (
        <Link className="navbar-brand" to={homeLink} title="avoc">
            <Logo />
            <span className="navbar-brand-slogan">{slogan}</span>
        </Link>
    )
}

export default Brand;