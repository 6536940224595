import * as React from "react";
import "./style.scss";

const BlockText = ({ title, iconCaption, description, iconImage }) => {

    return (
        <article>
            <header>
                <figure className="block-text-icon">
                    <img className="block-text-icon-image" src={iconImage} alt={iconCaption} />
                    <figcaption className="block-text-icon-caption">{iconCaption}</figcaption>
                </figure>
                <h3 className="block-text-title">{title}</h3>
            </header>
            <p className="block-text-description" dangerouslySetInnerHTML={{
                __html: description
            }} />
        </article>
    );
}

export default BlockText;