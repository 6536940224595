import React from 'react'
import { Helmet } from "react-helmet";
const Layout = ({ children, menuOpen }) => {
    return (
        <React.Fragment>
            <main className={`${menuOpen ? "is-menu-open" : ""}`} id="main" role="main">
                {children}
            </main>
            <Helmet>
                <script type="text/javascript" src="//www.freeprivacypolicy.com/public/cookie-consent/3.1.0/cookie-consent.js"></script>
                <script type="text/javascript" src="/truc.js"></script>
                <script type="text/plain" cookie-consent="tracking" async src="https://www.googletagmanager.com/gtag/js?id=G-2J4CH9P2VH"></script>
                <script type="text/plain" cookie-consent="tracking" src="/truc2.js"></script>
                <noscript>{`<p>Cookie Consent by <a href="https://www.FreePrivacyPolicy.com/free-cookie-consent/" rel="nofollow noopener">FreePrivacyPolicy.com</a></p>`}</noscript>
            </Helmet>
        </React.Fragment>
    )
}

export default Layout;