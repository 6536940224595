import * as React from "react";
import "./style.scss";
import { Link } from "gatsby";
import Brand from "../brand/brand";
import { StaticImage } from "gatsby-plugin-image";

const Footer = ({data}) => {
    return (
        <footer id="footer" className="footer">
            <div className="footer-container" id="contact">
                <div className="footer-logo">
                    <div className="hidden-sm-up">
                        <Brand slogan="Let our experience be your guide" />
                    </div>
                    <div className="hidden-sm-down">
                        <Link to="/" title="avoc - planification horaire avec hyperplanning">
                            <StaticImage src="../../images/logo-slogan-footer.svg" className="footer-logo-slogan" alt={data.logo.alt} />
                        </Link>
                    </div>
                </div>
                <div className="footer-copyright">
                    <p>{data.emailAddress}: <a href="mailto:avoc@avoc.ch">avoc@avoc.ch</a></p>
                    <p>{data.copyright}</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;