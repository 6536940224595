import * as React from "react";
import "./style.scss";
import { StaticImage } from "gatsby-plugin-image";

const About = ({data}) => {
    return (
        <div className="about">
            <StaticImage className="image" width="360" height="30" src="../../images/logo_slogan.svg" alt={data.image.alt} />
            <div className="row">
                <div className="col-md-6">
                    <h2 dangerouslySetInnerHTML={{ __html: data.title }}></h2>
                </div>
                <div className="col-md-6">
                    <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
                </div>
            </div>
        </div>
    );
}

export default About;