import { Link } from "gatsby";
import React, { useState } from 'react'
import Brand from "../brand/brand";
import Nav from "../nav/nav";
import "./style.scss";

const Header = ({ handleIsMenuOpen, data, homeLink }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleClick = () => {
        const isMenuOpen = !menuOpen;
        setMenuOpen(isMenuOpen);
        handleIsMenuOpen(isMenuOpen);
    }

    return (
        <header id="header">
            <nav className="navbar navbar-expand-lg navbar-light bg-white">
                <div className="container">
                    <button onClick={() => handleClick()} className={`navbar-toggler ${menuOpen ? "" : "collapsed"}`} type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <Brand slogan="Let our experience be your guide" homeLink={homeLink} />
                    <div className={`collapse navbar-collapse ${menuOpen ? "show" : ""}`} id="navbarNav">
                        <Nav data={data} homeLink={homeLink} />
                        <div className="hidden-sm-down">
                            <ul className="navbar-nav ml-auto language-nav" role="navigation" aria-label="Choix the langue">
                                <li className="nav-item">
                                    <Link className="nav-link" activeClassName="active" to="/" title="Français">fr</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClassName="active" to="/en" title="English">en</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClassName="active" to="/it" title="Italiano">it</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="hidden-sm-up">
                            <ul className="navbar-nav ml-auto" role="navigation" aria-label="Choix the langue">
                                <li className="nav-item">
                                    <Link className="nav-link" activeClassName="active" to="/" title="Français">français</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClassName="active" to="/en" title="English">english</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClassName="active" to="/it" title="Italiano">italiano</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header;