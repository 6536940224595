import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./style.scss";

const Chart = ({ percentage, label, icon, id }) => {
    const className = ['chart', id].join(' ');

    const appendDot = () => {
        if (document.getElementById('circle-1') === null) {
            document.querySelector('#chart-1 svg').appendChild(generateCircle(90, 70, 'circle-1'));
            document.querySelector('#chart-2 svg').appendChild(generateCircle(94, 40, 'circle-2'));
            document.querySelector('#chart-3 svg').appendChild(generateCircle(45, 94, 'circle-3'));
        }

        document.querySelectorAll('.CircularProgressbar').forEach((circularProgressbar) => {
            circularProgressbar.setAttribute('viewBox', '0 0 105 105');
        });
    }

    const generateCircle = (cx, cy, id) => {
        const circle = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
        circle.setAttribute('id', id);
        circle.setAttribute('class', 'CircularProgressbar-bullet');
        circle.setAttribute('cx', cx);
        circle.setAttribute('cy', cy);
        circle.setAttribute('r', 5);

        return circle;
    }

    return (
        <div className={className} id={id}>
            <div className="chart-circle">
                <VisibilitySensor onChange={appendDot}>
                    {({ isVisible }) => {
                        const percentageAnimated = isVisible ? percentage : 0;
                        return <CircularProgressbar className={`is-visivle-${isVisible}`} value={percentageAnimated} minValue="0" />
                    }}
                </VisibilitySensor>
                <img className="chart-icon" src={icon} alt={label} />
            </div>
            <div className="chart-metada">
                <p className="chart-percentage">{percentage}%</p>
                <h2 className="chart-label">{label}</h2>
            </div>
        </div>
    );
}

export default Chart;